<template>
  <div class="kuang_ping_nwes">
    <div class="px1200 nimen_news">
      <div class="shouye_biapt clas_news_bt">
        <div class="mingz_news"><span><my-link href="/home-layout">首页</my-link> > <my-link href="/topic">品牌荣誉</my-link></span></div>
      </div>
      <div class="chanp_biapt">
        <h2>品牌荣誉</h2>
      </div>
      <div class="news_list_ziye">
        <ul>
          <li v-for="topic in topics" :key="topic.id">
            <my-link :href="'/topic/detail?sid='+topic.id"><img :src="httpPath + topic.imgUrl" alt=""></my-link>
            <div class="text">
              <my-link :href="'/topic/detail?sid='+topic.id"><h2 :title="topic.title">{{ topic.title }}</h2></my-link>
              <p :title="topic.description">{{ topic.description }}</p>
              <my-link :href="'/topic/detail?sid='+topic.id" class="xiangq">查看详情>></my-link>
            </div>
          </li>
        </ul>
      </div>
      <!-- 分页组件 -->
      <pagination v-bind:child-msg="pageParam" @callFather="callFather"></pagination>
    </div>
  </div>

  <!--  <div style="width: 100%">-->
<!--    <div class="wrap-lg">-->
<!--      <div>-->
<!--        <div class="top-title"><img src="@/assets/img/titleimg.png"><span class="title-text">品牌荣誉</span></div>-->
<!--      </div>-->
<!--      <div class="list-topic">-->
<!--        <div class="topic-box">-->
<!--          <div v-for="topic in topics" :key="topic.id" class="topic-box-item">-->
<!--&lt;!&ndash;            <my-link :href="'/topic/detail?sid='+topic.id">&ndash;&gt;-->
<!--              <div class="box-item-center">-->
<!--                <div class="box-item-img">-->
<!--                  <img :src="httpPath + topic.imgUrl" style=" display: block; width: 100%; height: 100%"/>-->
<!--                </div>-->
<!--                <div class="box-item-text">-->
<!--                  <div class="box-title beyond-hide">-->
<!--                    <span :title="topic.title">{{ topic.title }}</span>-->
<!--                  </div>-->
<!--                  <div class="box-desc multi-hide">-->
<!--                    <span :title="topic.description">{{ topic.description }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--&lt;!&ndash;            </my-link>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div style="text-align: center;font-size: 16px;color: #461214;margin-top: 25px;">没有更多啦~</div>-->
<!--  </div>-->
</template>

<script>
import { topicList } from '@/api/platform/topic'

export default {
  name: 'index',
  data () {
    return {
      httpPath: '',
      topics: [],
      // 分页参数
      pageParam: {
        currentPage: 1,
        pageSize: 10,
        total: 10,
        pageSizes: [10, 20, 30, 50, 100]
      }
    }
  },
  mounted () {
    this.httpPath = this.commonUtils.getPara('httpPath')
    this.initData()
  },
  methods: {
    initData () {
      let param = {}
      // if (this.searchText) {
      //   param.name = this.searchText
      // }
      param.categoryCode = this.solutionType
      param.page = {
        pageNum: this.pageParam.currentPage,
        pageSize: this.pageParam.pageSize,
        orderBy: 'createTime'
      }
      this.loading = true
      topicList(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          if (result.bizData) {
            this.topics = result.bizData
            this.pageParam.total = result.total
          }
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    },
    callFather (parm) {
      this.pageParam.currentPage = parm.currentPage
      this.pageParam.pageSize = parm.pageSize
      this.initData()
    }
  }
}
</script>

<style lang="scss" scoped>
.list-topic {
  display: inline-flex;

  .topic-box {
    width: 1226px;

    .topic-box-item {
      background: #4f080e;
      float: left;
      margin-right: 50px;
      list-style: none;
      height: 180px;
      width: 520px;
      margin-top: 30px;
      text-align: left;
      border: 1px solid #f6b95e;

      .box-item-center {
        display: inline-flex;
        height: 100%;

        .box-item-img {
          width: 230px;
          height: 100%;
        }

        .box-item-text {
          margin: 20px auto auto 20px;

          .box-title {
            font-size: 18px;
            line-height: 30px;
            color: #f6b95e;
          }

          .box-desc {
            font-size: 14px;
            line-height: 30px;
            color: #f6b95e;
          }
        }
      }
    }
  }

}
.clas_news_bt .mingz_news {
  border-bottom: 0;
}
</style>
